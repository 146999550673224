<template>
  <rs-dialog
    title="修改密码"
    class="newProduct_block form-dialog"
    :visible.sync="showPassword"
    :close-on-click-modal="false"
    top="20vh"
    @close="handleClose"
  >
    <div class="">
      <rs-form :model="ruleForm2" status-icon :rules="rules2" size="small" ref="ruleForm2" class="demo-ruleForm commom-form">
        <!-- <rs-form-item prop="verifyCode" class="mg-b-10">
          <rs-input type="text" class="w59" v-model="ruleForm2.verifyCode" autocomplete="new-password" placeholder="验证码"></rs-input>
          <rs-button class="w30 getCount" size="mini" type="text" v-if="!countdownShow" @click="sendSMSCode">获取验证码</rs-button>
          <rs-button class="w30 getCount" size="mini" type="text" v-if="countdownShow">{{ countdownNum }}秒后重试</rs-button>
        </rs-form-item> -->
        <rs-form-item prop="originalPassword" class="mg-b-10">
          <rs-input type="password" v-model="ruleForm2.originalPassword" autocomplete="new-password" placeholder="旧密码"></rs-input>
        </rs-form-item>
        <rs-form-item prop="newpass" class="mg-b-10">
          <rs-input type="password" v-model="ruleForm2.newpass" autocomplete="new-password" placeholder="新登录密码"></rs-input>
        </rs-form-item>
        <rs-form-item prop="checkPass" class="mg-b-0">
          <rs-input type="password" v-model="ruleForm2.checkPass" autocomplete="new-password" placeholder="确认新密码"></rs-input>
        </rs-form-item>
      </rs-form>
    </div>

    <!-- <div slot="footer"> -->
    <rs-button type="primary" class="submit_btn" @click="submitForm('ruleForm2')">确定</rs-button>
    <!-- </div> -->
  </rs-dialog>
</template>

<script>
import Http from '@/api/api'
import Utils from '../utils/utils'
import Secret from '../utils/secret'
export default {
  name: 'changePassword',
  data() {
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'))
      } else {
        callback()
      }
    }
    var validatenewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新登录密码'))
      } else if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(value)) {
        callback(new Error('新密码字符长度为6-18位数字和字母组合'))
      } else {
        if (this.ruleForm2.checkPass !== '') {
          this.$refs.ruleForm2.validateField('checkPass')
        }
        callback()
      }
    }
    var validatecheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新登录密码'))
      } else if (value !== this.ruleForm2.newpass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm2: {
        checkPass: '',
        newpass: '',
        originalPassword: ''
      },
      rules2: {
        originalPassword: [{ validator: validateCode, trigger: 'change' }],
        newpass: [{ validator: validatenewPass, trigger: 'change' }],
        checkPass: [{ validator: validatecheckPass, trigger: 'change' }]
      },
      countdownShow: false, // 获取验证码和秒数的flag
      countdownNum: 60 // 倒计时初始值60s
    }
  },
  props: {
    showPassword: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit()
        } else {
          return false
        }
      })
    },
    submit() {
      const vm = this
      let params = {
        id: Utils.getSession('userInfo').id,
        newPassword: Secret.Encrypt(this.ruleForm2.newpass),
        reNewPassword: Secret.Encrypt(this.ruleForm2.checkPass),
        originalPassword: Secret.Encrypt(this.ruleForm2.originalPassword)
      }
      Http.modifyPwd(params)
        .then((res) => {
          const dialogChildSec = document.querySelector('.v-modal')
          dialogChildSec && document.body.removeChild(dialogChildSec)
          this.$alert('密码更新成功,请重新登陆', '提示', {
            confirmButtonText: '确定',
            callback: (action) => {
              Utils.delLocalStorage('userInfo')
              Utils.delLocalStorage('buttonResource')
              Utils.delSessionStorage('userInfo')
              Utils.delSessionStorage('buttonResource')
              // vm.$router.push('/login')
              top.location.href = top.location.href.split('/#/')[0] + '/#/login'
            }
          })
        })
        .catch((error) => {})
    },
    resetForm() {
      this.$refs.ruleForm2.resetFields()
    },
    hide() {
      this.$emit('hidePassword', false)
      setTimeout(() => {
        this.resetForm('ruleForm2')
      })
    },
    // 获取短信验证码
    sendSMSCode() {
      let params = Utils.getSession('userInfo').mobile
      this.countdownShow = true
      this.countdownNum = 60
      this.countdownFun()
      Http.sendVerifyCodeByModifyPwd(params)
        .then((res) => {
          let phone = params.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')
          if (res.data.code === 200) {
            this.$message({
              message: `验证码已发送至${phone}的手机号，请注意查收!`,
              type: 'success'
            })
          }
        })
        .catch(() => {})
    },
    // 倒计时操作
    countdownFun() {
      // 验证码倒计时
      if (this.countdownShow) {
        this.countdownNum--
        if (this.countdownNum === 0) {
          this.countdownShow = false
          this.countdownNum = 60
          return false
        }
        this.sleepFun()
      }
    },
    sleepFun() {
      setTimeout(() => {
        this.countdownFun()
      }, 1005)
    },
    handleClose() {
      this.countdownNum = 60
      this.countdownShow = false
      this.hide()
    },
    init() {
      this.ruleForm2 = {
        checkPass: '',
        newpass: '',
        verifyCode: ''
      }
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
.newProduct_block {
  /deep/.rs-dialog {
    width: 432px;
    height: 338px;
    border-radius: 6px;
  }
  /deep/.rs-dialog__header {
    border-bottom: 1px solid #ccc;
  }
  /deep/.rs-dialog__body {
    padding: 24px 36px !important;
  }
  /deep/.rs-dialog .rs-dialog__footer {
    text-align: center;
    padding: 0px 36px !important;
  }
  .text {
    text-align: left;
    margin-bottom: 10px;
    padding-left: 100px;
  }
  .footer_button {
    text-align: center;
  }
  .mg-b-10 {
    margin-bottom: 18px;
  }
  .mg-b-0 {
    margin-bottom: 0px;
  }
  .submit_btn {
    width: 100%;
    margin-top: 32px;
    height: 36px;
    padding: 10px 20px;
  }
  .w59 {
    width: 66%;
  }
  .w30 {
    width: 31%;
  }
  .font-black {
    color: rgba(0, 0, 0, 0.5);
  }
  .countdownLoad {
    display: inline-block;
    height: 36px;
    color: #000;
    line-height: 40px;
  }
  .getCount {
    height: 30px;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    margin-left: 3%;
    border-radius: 0 !important;
    text-align: center;
  }
  .countdownLoad span {
    font-size: 12px;
  }
}
</style>
