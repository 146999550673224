<template>
  <div :class="isCollapse ? 'main_menu_thin' : 'main_menu'">
    <!-- <div class="menu" :class="{thin:isCollapse}"> -->
    <rs-menu
      :class="[{ thin: isCollapse }, 'menu rs-menu-vertical-demo']"
      :collapse="isCollapse"
      background-color="#ffffff"
      text-color="#FFFFFF"
      active-text-color="#3370FF"
      :unique-opened="true"
      :defaultActive="defaultActive"
      @select="selectItems($event)"
    >
      <div v-for="(item, index) in menu.childResourceVoList" :key="index" style="margin-top: 4px">
        <rs-submenu :index="item.resourceName + '&' + item.resourceUrl" v-if="item.childResourceVoList !== null">
          <template slot="title">
            <a :href="'#/?link=' + item.resourceUrl" @click.prevent="prevent">
              <i class="font_family menuIcon" v-html="item.iconCode"></i>
              <span class="title_span" v-show="!isCollapse" :class="{ paddingRight: !isCollapse }" slot="title">{{ item.resourceName }}</span>
            </a>
          </template>
          <div v-for="(value, num) in item.childResourceVoList" :key="num" style="margin-top: 4px">
            <rs-menu-item
              class="menuitem-sub"
              v-if="value.childResourceVoList == null"
              :index="value.resourceName + '&' + value.resourceUrl"
              :key="num"
            >
              <a class="two" :href="'#/?link=' + value.resourceUrl" @click.prevent="prevent">{{ value.resourceName }}</a>
            </rs-menu-item>
            <rs-submenu v-else :index="value.resourceName + '&' + value.resourceUrl" :popper-append-to-body="true">
              <template slot="title">
                <a class="menuitem-sub" :href="'#/?link=' + value.resourceUrl" @click.prevent="prevent">
                  <!-- <i class="font_family" v-html="value.iconCode" v-if="item.iconCode"></i> -->
                  <span class="title_span" :class="{ paddingRight: !isCollapse }" slot="title">{{ value.resourceName }}</span>
                </a>
              </template>
              <rs-menu-item
                style="margin-top: 4px"
                :index="val.resourceName + '&' + val.resourceUrl"
                v-for="(val, idx) in value.childResourceVoList"
                :key="idx"
              >
                <a class="menuitem-thr" :href="'#/?link=' + val.resourceUrl" @click.prevent="prevent">{{ val.resourceName }}</a>
              </rs-menu-item>
            </rs-submenu>
          </div>
        </rs-submenu>
        <rs-menu-item :index="item.resourceName + '&' + item.resourceUrl" v-else :class="{ paddingRight: !isCollapse }">
          <a :href="'#/?link=' + item.resourceUrl" @click.prevent="prevent">
            <i class="font_family menuIcon" v-html="item.iconCode"></i>
            <span class="title_span" v-show="!isCollapse" :class="{ paddingRight: !isCollapse }">{{ item.resourceName }}</span>
          </a>
        </rs-menu-item>
      </div>
    </rs-menu>
    <!-- </div> -->
  </div>
</template>

<script>
import Utils from '@/utils/utils'
import Http from '@/api/api'
export default {
  data() {
    return {
      menu: '',
      logoImg: '',
      defaultActive: ''
    }
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectItems(index) {
      if (index.split('&')[1] !== '' || index.split('&')[1] !== null) {
        if (index.split('http://').length > 1 || index.split('https://').length > 1) {
          //外网链接
          this.$emit('jumpUrl', index, 'http')
        } else {
          this.$emit('jumpUrl', index)
        }
      }

      Utils.setSession('leftMenuInit', index)
    },
    getTree() {
      Http.getMenuResource({ userId: Utils.getSession('userInfo').id })
        .then((res) => {
          let getData = JSON.parse(JSON.stringify(res.data.data))
          this.menu = JSON.parse(JSON.stringify(getData))
          this.menu.childResourceVoList = []
          this.menu.childResourceVoList = JSON.parse(JSON.stringify(getData.childResourceVoList))
          this.menu = res.data.data;
          this.menu.childResourceVoList = (this.menu.childResourceVoList||[]).filter((item)=>{
            return item.resourceCode != 'SGYDD'
          });
           var tempBench=[]
          if(this.menu.workBenchVoList && this.menu.workBenchVoList.length){
            this.menu.workBenchVoList.forEach(item=>{
              if(item.hasPermissions == 1){
                tempBench.push(item.resourceCode)
              }
            })
          }
          Utils.setSession('workBenchVoList',JSON.stringify(tempBench))
          // 默认选择第一个菜单，第一个菜单是不是首页，如果不是，就不能这样写
          // this.selectItems(`${this.menu.childResourceVoList[0].resourceName}&${this.menu.childResourceVoList[0].resourceUrl}`)
          try {
            let index = this.menu.childResourceVoList.findIndex((item) => {
              return item.resourceName === '工作台'
            })
            if (index >= 0) {
              this.selectItems(`${this.menu.childResourceVoList[index].resourceName}&${this.menu.childResourceVoList[index].resourceUrl}`)
            }
          } catch (_) {}


          this.defaultActive = Utils.getSession('leftMenuInit', false)
          this.$emit('getMenuResource', this.menu.childResourceVoList)
        })
        .catch(() => {})
    },
    setImg() {
      let img = Utils.getLocalStorage('imgUrl')
      if (img) {
        img.forEach((v) => {
          if (v.imageType === 3) {
            this.logoImg = v.imageUrl
          }
        })
      }
    },
    getButton() {
      Http.getButtonResource().then((res) => {
        Utils.setSession('buttonResource', res.data.data)
      })
    },
    postMessageListener(e) {
      const vm = this
      if (e.data.type === 'uploadImg') {
        vm.setImg()
      }
    },
    prevent(event) {
      event.preventDefault()
    }
  },
  mounted() {
    window.addEventListener('message', this.postMessageListener)
    this.getTree() //获取树型结构
    this.getButton()
    this.setImg()
  }
}
</script>
<style scoped>

.menu {
  z-index: 5;
  height: 100%;
  transition: all 0.1s;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  overflow: auto;
  background: #ffffff;
  padding: 0 4px;
}
.menu.thin {
  width: 50px;
  overflow-x: hidden;

}

.thin .menu_div {
  left: 50px;
}

.title_span {
  display: inline-block;
  text-align: left;
  width: 60px;
    color: rgb(78, 89, 105) !important;

}
.two{
    color: rgb(78, 89, 105) !important;


}

>>> .thin .rs-icon-arrow-right {
  display: none;
}
>>> .rs-submenu__icon-arrow {
  position: absolute;
  right: 7px;
  opacity: 0.8;
}
>>> .rs-submenu__icon-arrow::before {
  content: '\e790';
    color: rgb(78, 89, 105) !important;

}
.rs-menu {
  border: 0;
  background: #f9f9f9;
}
.rs-menu-item.is-active,
.menu .is-active,
.rs-menu-item.is-active:hover {
  background: rgb(242, 243, 245) !important;
  border-radius: 4px;
  color: #fff !important;
}
.rs-menu-item.is-active a {
  color: rgb(22, 93, 255) !important;
}
/deep/.rs-menu--collapse {
  width: 47px;
}
.main_menu {
  /* box-shadow: 2px 0px 4px 0 rgba(0,21,41,0.12); */
  /* margin-right:4px; */
  height: 100%;
  overflow: auto;
  width: 12%;
  min-width: 200px;
  max-width: 250px;
}
.main_menu_thin {
  height: 100%;
  overflow: auto;
}
/deep/.rs-menu-item,
/deep/.rs-submenu__title {
  height: 40px !important;
  line-height: 39px !important;
  padding: 0 8px !important;
}
/deep/ .menu > .rs-menu > div > .is-opened > .rs-submenu__title i {
  color: #fff;
}
.menu > .rs-menu > div > .is-opened > .rs-submenu__title span {
  color: #fff;
}
/deep/.rs-menu-item i,
.rs-submenu__title a i {
  margin-right: 8px;
  margin-top: -2px;
}
.rs-menu-item {
  padding: 0 8px !important;
}
.thin .rs-menu-item.is-active {
  background: transparent !important;
}
.thin .rs-menu-item.is-active i {
  background: #3370ff !important;
}
/deep/.thin .rs-menu-item:hover,
/deep/.thin .rs-submenu__title {
  width: 40px;
}
/deep/.rs-submenu__title:hover,
.rs-menu-item:hover {
 background: rgb(242, 243, 245) !important;
  border-radius: 4px;
}
a {
  color: #ffffff;
}
::-webkit-scrollbar {
  width: 1px;
}
.menuIcon {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: rgb(78, 89, 105) !important;
  border-radius: 4px;
  text-align: center;
  margin-right: 16px;
  background: rgba(255, 255, 255, 0.15);
}
.menuitem-sub:before {
  content: '';
  background-color: #7c878e;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: text-top;
  margin-top: 6px;
  margin-left: 9px;
}
.menuitem-sub.is-active:before {
  background-color: #ffffff;
}
.menuitem-thr{
    color: rgb(78, 89, 105) !important;

}
.menuitem-thr:before {
  content: '';
  background-color: #7c878e;
  margin-right: 42px;
  display: inline-block;
  vertical-align: text-top;
  margin-top: 8px;
  margin-left: 6px;
}
.rs-menu--popup .menuitem-thr:before,
.rs-menu--popup .menuitem-sub:before {
  display: none;
}
.rs-menu.rs-menu--popup .rs-menu-item {
  margin: 0 4px;
}
/deep/.rs-icon-arrow-right::before {
  content: '\e791';
  color: #fff;
}
/deep/.is-opened .rs-icon-arrow-right {
  transform: rotate(0deg) !important;
}
</style>
