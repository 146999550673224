<template>
  <rs-container style="height: 100%; min-width: 1080px">
    <div v-if="maskFlag" class="msgDivImg"></div>
    <rs-header class="main_header" style="height: 52px; background: #ffffff">
      <!-- 头部那部分 -->
      <p class="header_title">
        <span class="logo">
          <img :src="imgForm.logoImgType1 == '1' ? imgForm.customIndexLogo : imgForm.customIndexDefaultLogo" />
          <p class="one">{{ imgForm.customIndexTitle }}</p>
        </span>
        <span v-html="!isCollapse ? '&#xe77b;' : '&#xe77a;'" class="font_family menuShouqi" @click="isCollapse = !isCollapse"></span>
      </p>

      <!-- 最右边那部分 -->
      <div class="button_block">
        <rs-dropdown trigger="click" @command="handleCommand" class="user_info_block" @visible-change="changeClass">
          <span class="user_info" :class="{ cur: show }">
            <span class="font_span">{{ userName }}</span>
          </span>
          <rs-dropdown-menu slot="dropdown">
            <rs-dropdown-item command="change">
              <i class="icon_password"></i>
              修改密码
            </rs-dropdown-item>
            <rs-dropdown-item command="logout">
              <i class="icon_logout"></i>
              退出登录
            </rs-dropdown-item>
          </rs-dropdown-menu>
        </rs-dropdown>
      </div>
    </rs-header>
    <rs-container class="main-container" style="height: calc(100% - 52px)">
      <menu-div :isCollapse="isCollapse" ref="menu" @jumpUrl="jumpUrl" @hasSy="hasSy" @getMenuResource="getMenuResource"></menu-div>
      <!-- <div v-if="nopower==true" class="nopower">
                <img src="../../assets/images/icon-no-auth.png" alt />
                <p class="no-auth-text">您暂时无法访问系统</p>
                <p class="no-auth-text-span">如需访问，请提交账号开通流程，如已提交流程，请联系IT客服处理</p>
            </div> -->

      <rs-main style="box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2)">
        <!-- 中间那部分 -->
        <div class="main rs-container tab-top">
          <!-- <rs-tabs
        v-model="editableTabsValue"
        type="card"
        @tab-click="tabClick"
        @tab-remove="removeTab"
        class="main rs-container is-vertical"
        style="height:100%;"
      >
        <rs-tab-pane
          v-loading="item.loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="rs-icon-loading"
          element-loading-background="rgba(249, 249, 249, 0.8)"
          v-for="(item, index) in editableTabs"
          :key="item.name + index"
          :label="item.title"
          :name="item.name"
          :closable="item.closeable"
         
          class="rs-main"
        >
   
        </ers-tab-pan>
      </rs-tabs> -->
          <div class="left">
            <ul class="el-dropdown-menu">
              <li
                v-for="(item, index) in editableTabs"
                class="el-dropdown-menu__item"
                aria-disabled="false"
                tabindex="-1"
                :key="index"
                @click="tabClick(item, index, 1)"
                @contextmenu.prevent="onRightClick(item, index)"
              >
                <div :class="{ content, color: activeIndex == index, rightColor: isShowIndex == index }">
                  <span>{{ item.title }}</span>
                  <!-- <SvgIcon :name="v.url" /> -->
                  <i v-if="index !== 0" class="rs-icon-close" @click.stop="removeTab(item.name)"></i>
                </div>
                <div v-if="isShowIndex == index">
                  <ul v-if="meunVisiable" class="menu ll">
                    <li v-for="(v, k) in meunList" :key="k" @click="jumpPage(v, k, index)">
                      <i :class="'iconfont ' + v.icon"></i>
                      <span>{{ v.title }}</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <!-- <div class="right">
            <rs-button v-show="editableTabs.length" @click="closeAllTabs" size="small" class="close-all-button" icon="rs-icon-close"></rs-button>
            <rs-popover trigger="hover" width="934" popper-class="all-tabs-popover">
              <div class="booth-box">
                <div class="orgrole-wrap">
                  <p
                    :class="['orgrole-item', { 'orgrole-item-active': item.name == editableTabsValue }]"
                    @click="tabClick(item, index)"
                    v-for="(item, index) in editableTabs"
                    :key="index"
                  >
                    <span>{{ item.title }}</span>
                    <i @click.stop="removeTab(item.name)" class="rs-icon-close" style="color: rgb(78, 89, 105) !important"></i>
                  </p>
                </div>
              </div>
              <rs-button
                v-show="editableTabs.length"
                slot="reference"
                size="small"
                style="color: rgb(78, 89, 105); font-size: 12px !important"
                class="close-all-button"
              >
                全览
              </rs-button>
            </rs-popover>
          </div> -->
        </div>

        <rs-tabs v-model="editableTabsValue" type="card" @tab-remove="removeTab" class="main rs-container is-vertical home-main" style="height: 100%">
          <rs-tab-pane
            v-loading="item.loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="rs-icon-loading"
            element-loading-background="rgba(249, 249, 249, 0.8)"
            v-for="(item) in editableTabs"
            :key="item.name + (item.randomKey || '')"
            :label="item.title"
            :name="item.name"
            :closable="item.closeable"
            class="rs-main"
            ref="frameTab"
          >
            <iframe id="main_iframe" ref="mainIframe" :src="item.url" ></iframe>
          </rs-tab-pane>
        </rs-tabs>
        <!-- <div style="height:100%;position: absolute;width:100%;" v-if="loadingFlagHome"
                     v-loading="loadingFlagHome"
                     element-loading-text="拼命加载中"
                     element-loading-spinner="rs-icon-loading"
                     element-loading-background="rgba(255, 255, 255, 0.8)">
                </div> -->
        <!-- <iframe id="iframeHome" class="iframeHomeIndexArr" ref="mainIframe" :src="iframeUrl"></iframe> -->
        <!--</div>-->
      </rs-main>
    </rs-container>
    <change-password ref="ChangePassword" :showPassword="showPassword" @hidePassword="hidePassword"></change-password>
  </rs-container>
</template>

<script>
import Utils from '@/utils/utils'
import Http from '@/api/api'
import OaApi from '@/api/oa'
import MenuDiv from '@/components/leftMenu'
import changePassword from '../../components/changePassword'
// import redirect from '@/webconfig'
import ImgApi from '@/api/imgSetter'
import eventBus from '@/utils/eventBus'
import '@/assets/font//iconfont.css'
export default {
  name: 'home',
  components: {
    MenuDiv,
    changePassword
  },
  data() {
    return {
      isShowIndex: null,
      activeIndex: 0,
      isLoding: true,
      show: false,
      dialogVisible: false,
      showPassword: false,
      showQuestion: false,
      UserLeftComplaint: 0,
      userName: '',
      shopName: '',
      editableTabsValue: 'main',
      editableTabs: [],
      tabIndex: 1,
      NoticeImgData: '',
      fresh: false,
      maskFlag: false,
      iframeUrl: '',
      collapseFlag: false, // 菜单展开合并,
      loadingFlagHome: false,
      imgForm: {},
      oaCode: '', // 是否从oa进入
      nopower: false,
      isCollapse: false, // 菜单展开收起,
      meunVisiable: false,
      meunList: [
        {
          title: '关闭当前标签',
          type: 0,
          icon: 'icon-guanbidangqianbiaoqianye'
        },
        {
          title: '关闭左侧标签',
          type: 1,
          icon: 'icon-guanbizuocebiaoqianye'
        },
        {
          title: '关闭右侧标签',
          type: 2,
          icon: 'icon-guanbiyoucebiaoqianye'
        },
        {
          title: '关闭其他标签',
          type: 3,
          icon: 'icon-guanbiqitabiaoqian'
        },
        {
          title: '关闭全部标签',
          type: 4,
          icon: 'icon-guanbiquanbubiaoqianye'
        }
      ]
    }
  },
  methods: {
    // 点击菜单选项逻辑
    jumpPage(item, index, tagindex) {
      // console.log(index)
      // console.log(tagindex)
      // console.log(item, 'item')
      // console.log(this.editableTabs)
      if (item.type == 0) {
        this.editableTabs.splice(tagindex, 1)
      }
      if (item.type == 1) {
        if (tagindex == 0) {
        } else {
          this.editableTabs.splice(tagindex - 1, 1)
        }
      }
      if (item.type == 2) {
        if (tagindex == this.editableTabs.length - 1) {
        } else {
          this.editableTabs.splice(tagindex + 1, 1)
        }
      }
      if (item.type == 3) {
        this.editableTabs = this.editableTabs.filter((item, index) => {
          if (index == tagindex) {
            return item
          }
        })
      }
      if (item.type == 4) {
        this.editableTabs = []
      }
      this.AddFun()
    },
    AddFun() {
      let data = this.editableTabs.length
      if (data > 0) {
        let length = this.editableTabs.length - 1

        this.tabClick(this.editableTabs[length])
      }
    },

    // 鼠标离开事件
    // onMouseLeave() {
    //   this.isShowIndex = null
    // },
    isShowIndexShow() {
      console.log('@click="isShowIndexShow"');
      this.isShowIndex = null
    },

    // 右击事件
    onRightClick(item, index) {
      console.log(666)
      this.isShowIndex = index
      this.meunVisiable = true
      document.addEventListener('click', this.hideMeun)
    },

    hideMeun() {
      this.isShowIndex = null,
      this.meunVisiable = false
      document.removeEventListener('click', this.hideMenu)
    },
    openPags(v, index) {
      this.$router.push(`${v.partUrl}`)
      console.log(v, index)
    },
    // 从oa跳转进来获取重定向信息
    getZjInfo() {
      OaApi.getZjInfo().then((res) => {
        this.$store.dispatch('setOaZjInfo', res.data.data)
      })
    },
    changeClass(e) {
      this.show = e
    },
    changeMenu() {
      this.collapseFlag = !this.collapseFlag
      this.$refs.menu.bindCollapse()
    },
    // 获取图片
    getImg() {
      ImgApi.findConfigByConfigGroup({
        configGroup: 2
      }).then((res) => {
        this.imgForm = res.data.data
        Utils.setSession('imgInfo', this.imgForm)
        Utils.setLocalStorage('imgInfo', this.imgForm)
        this.$forceUpdate()
      })
    },
    handleCommand(command) {
      if (command === 'change') {
        this.showPassword = true
        this.$refs.ChangePassword.init()
      } else if (command === 'logout') {
        this.$confirm('确定退出吗', '提示', {
          confirmButtonText: '退出',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            Http.logout().then((res) => {
              Utils.delLocalStorage('userInfo')
              Utils.delLocalStorage('buttonResource')
              Utils.delSessionStorage('userInfo')
              Utils.delSessionStorage('leftMenuInit')
              Utils.delSessionStorage('workBenchVoList')
            })
          })
          .catch(() => {})
      }
    },
    handledrop(command) {
      // if(command === 'question'){
      //     this.showQuestion = true
      // } else if(command === 'help'){
      //     //postMessage.openTab('公告','/administration/#/administrationNoticeList')
      // }
    },
    hidePassword() {
      this.showPassword = false
    },
    hideQuestion() {
      this.showQuestion = false
    },
    jumpUrl(url, type) {
      let array = url.split('&')
      // let Url = url
      let Url = url.split('&')[1]
      array.forEach((item, index) => {
        if (index == 1) {
          Url = item
        } else if (index > 1) {
          Url = Url + '&' + item
        }
      })
      if (type == 'http') {
        //直接跳外网，无需处理
        // Url = url.split('&')[1]
        let title = url.split('&')[0]
        //直接跳外网，无需处理
        if (Url.indexOf('admin') > -1 || Url.indexOf('data') > -1) {
          //只有大数据的菜单给加token
          let token = Utils.getLocalStorage('aegeanUserInfo', false)
          if (Url.indexOf('?') > -1) {
            Url = Url + `&token=${token}`
          } else {
            Url = Url + `?token=${token}`
          }
        }
        this.addTab(title, Url, '', type)
        // this.$refs.mainIframe.contentWindow.location.href = url
      } else {
        Url = Url || '/#/' + new Date().getTime()
        let title = url.split('&')[0]
        // this.setUrl(Url)
        // 组织管理&/#/organization
        // Url = Url.replace(/&/g, '&').replace(/"/g, '\"').replace(/</g, '<').replace(/>/g, '>')
        // this.iframeUrl = Url
        // this.$refs.mainIframe.contentWindow.location.href = window.location.href.split('/#/')[0] + Url
        this.addTab(title, Url)
      }
    },
    setUrl(url) {
      url = url.replace(/%2F/g, '/').replace(/%23/g, '#').replace(/%3D/g, '=').replace(/%26/g, '&').replace(/%3F/g, '?')
      console.log(url, '22')
      this.$router.push({
        query: {
          link: url,
          hash: new Date().getTime()
        }
      })
    },
    hasSy() {
      //判断是否有首页
      this.addTab('首页', '/#/worktable')
    },
    // 判断菜单权限
    getMenuResource(data) {
      let { href, link } = this.$route.query
      if (!data || !data.length) {
        this.nopower = true
        return
      }
      // if(!href && !link) {
      //     this.iframeUrl = data[0].resourceUrl || '/#/main'
      //     return
      // }
    },
    // 全部关闭所有页签
    closeAllTabs() {
      this.$confirm('确定要关闭所有弹窗吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.editableTabs.forEach((item) => {
            this.removeTab(item.name)
          })
        })
        .catch(() => {})
    },
    postMessageListener(e) {
      const vm = this
      if (e.data.type === 'showMask') {
        this.maskFlag = true
      } else if (e.data.type === 'hideMask') {
        this.maskFlag = false
      } else if (e.data.type === 'jumpUrl') {
        this.jumpUrl(e.data.url)
      } else if (e.data.type === 'noLogin') {
        localStorage.removeItem('userInfo')
        top.location.reload()
      } else if (e.data.type === 'openTab') {
        this.addTab(e.data.name, e.data.targetUrl, e.data.currentUrl)
      } else if (e.data.type === 'alertDialog') {
        console.log(e.data.data)
      } else if (e.data.type == 'closeOtherTab') {
        if (e.data.targetUrl) {
          vm.editableTabs.forEach((item) => {
            console.log(item)
            // if(item.url==decodeURI(e.data.currentUrl)){  //防止url中 中文转译
            if (item.url.split('?')[0] == e.data.targetUrl.split('?')[0]) {
              //防止url中 中文转译
              //关闭老页面
              vm.removeTab(item.name)
            }
          })
        }
      } else if (e.data.type == 'closeAllTab') {
        vm.closeAllTabs()
      } else if (e.data.type === 'closeTab') {
        if (e.data.currentUrl) {
          vm.editableTabs.forEach((item) => {
            // console.log(item);
            // if(item.url==decodeURI(e.data.currentUrl)){  //防止url中 中文转译
            if (item.url.split('?')[0] == e.data.currentUrl.split('?')[0]) {
              //防止url中 中文转译
              //关闭老页面
              vm.removeTab(item.name)
              //返回对应的页面
              const fromName = item.from.split('/#/')[1].split('?')[0]
              if (e.data.refresh) {
                vm.editableTabs.forEach((tab) => {
                  if (fromName == tab.partUrl) {
                    let branch = tab.url.match(/\/(\w*?)(?=\/#\/)/)
                    // console.log(branch);
                    if (branch && location.href.match(/\/(\w*?)(?=\/#\/)/) && location.href.match(/\/(\w*?)(?=\/#\/)/)[0] === branch[0]) {
                      branch = null
                    }
                    let url = branch ? branch[0] + '/#/' + tab.url.split('/#/')[1] : '/#/' + tab.url.split('/#/')[1]
                    // let url ='/'+branch[0]+'/#/'+str.split('/#/')[1]
                    // console.log(url);
                    vm.addTab(tab.title, url, tab.from)
                  }
                })
              } else {
                const fromName = item.from.split('/#/')[1].split('?')[0]
                let indexd = ''
                vm.editableTabs.forEach((tab, index) => {
                  if (fromName == tab.partUrl) {
                    indexd = index
                  }
                })
                this.editableTabsValue = vm.editableTabs[indexd].name
              }
            }
          })
        } else {
          // this.$router.back(-1)
          this.removeTab(this.editableTabsValue)
        }
      } else if (e.data.type == 'hideLoading') {
        this.loadingFlagHome = false
        if (e.data.url) {
          const url = window.location.href.split('/#/')[0] + e.data.url
          if (this.editableTabs && this.editableTabs.length > 0) {
            this.editableTabs.forEach((item) => {
              if (item.url.split('?')[0] == url) {
                item.loading = false
              }
            })
          }
        }
      } else if (e.data.type == 'showLoading') {
        this.loadingFlagHome = true
        if (e.data.url) {
          const url = window.location.href.split('/#/')[0] + e.data.url
          this.editableTabs.forEach((item) => {
            if (item.url.split('?')[0] == url) {
              item.loading = true
            }
          })
        }
      }
    },
    addTab(title, url, from = '/#/main', type) {
      let flag = -1
      let name
      if (url) {
        if (url.split('/#/').length > 1) {
          name = url.split('/#/')[1].split('?')[0]
        } else if (!type || type != 'http') {
          name = url.split('?')[0]
          url = '/#' + url
        } else if (type == 'http') {
          name = url
        }
      } else {
        name = new Date().getTime()
        url = name
      }
      let partUrl = name
      name = name + title
      this.editableTabs.forEach((v, i) => {
        if (v.name === name) {
          flag = i
        }
      })
      // this.setUrl(url)
      if (flag === -1) {
        this.editableTabs.push({
          title: title,
          name: name,
          partUrl,
          url: type == 'http' ? url : window.location.href.split('/#/')[0] + url,
          closeable: true,
          from: from,
          loading: true
        })
      } else {
        console.log('title', title)
        console.log('url', url)
        if (type == 'http') {
          console.log('title', title)
          console.log('url', url)
          this.editableTabs[flag] = { ...this.editableTabs[flag], randomKey: Math.random() }
        } else {
          // this.iframeUrl = window.location.href.split('/#/')[0] + url
          this.editableTabs[flag].url = type == 'http' ? url : window.location.href.split('/#/')[0] + url
          this.editableTabs[flag].title = title
          this.$refs.mainIframe[flag].contentWindow.location.href = type == 'http' ? url : window.location.href.split('/#/')[0] + url
          this.$refs.mainIframe[flag].contentWindow.location.reload()
        }
      }
      this.editableTabsValue = name
      setTimeout(() => {
        if (this.editableTabs && this.editableTabs.length > 0) {
          this.editableTabs.forEach((item) => {
            item.loading = false
          })
        }
      }, 5000)
    },
    // tab切换
    tabClick(item, index, type) {
      this.activeIndex = index
      this.editableTabsValue = item.name
    },
    removeTab(targetName) {
      let tabs = this.editableTabs
      let activeName = this.editableTabsValue
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeName = nextTab.name
            }
          }
        })
      }

      this.editableTabsValue = activeName
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName)
    }
  },
  created() {
    top.window.$message = this.$message // 消息框
    top.window.$confirmDialog = this.$confirmDialog // 消息框
    top.window.$dialogManage = this.$dialogManage // 消息框
    if (Utils.getLocalStorage('userInfo')) {
      Utils.setSession('userInfo', Utils.getLocalStorage('userInfo'))
    }
    this.$nextTick(() => {
      if (this.$route.query.href && this.$route.query.href != 'undefined') {
        this.iframeUrl = `${window.location.href.split('/#/')[0]}${this.$route.query.href}`
      } else if (this.$route.query.link) {
        this.iframeUrl =
          window.location.href.split('/#/')[0] +
          this.$route.fullPath
            .split('?link=')[1]
            .split('&hash=')[0]
            .replace(/%2F/g, '/')
            .replace(/%23/g, '#')
            .replace(/%3D/g, '=')
            .replace(/%26/g, '&')
            .replace(/%3F/g, '?')
      }
    })
    // this.getZjInfo()
  },
  mounted() {
    window.addEventListener('message', this.postMessageListener)
    if (Utils.getSession('userInfo')) {
      this.userName = Utils.getSession('userInfo').userName
      if (Utils.getSession('userInfo').mallVos && Utils.getSession('userInfo').mallVos.length > 0) {
        this.shopName = Utils.getSession('userInfo').mallVos[0].mallName
      }
      Http.findSysUserInfo({
        loginNameOrMobile: Utils.getSession('userInfo').loginName,
        companyCode: '10001',
        loginSource: 'E_WEB',
        loginType: 'PASSWORD'
      }).then((res) => {
        if (res.data.code == 200) {
          Utils.setSession('userInfo', res.data.data)
          let tempSessionId = localStorage.getItem('aegeanUserInfo')
          document.cookie = `ESESSIONID=${tempSessionId}`
        }
      })
    }
    let imgForm = Utils.getLocalStorage('imgInfo')
    if (imgForm) {
      this.imgForm = imgForm
    } else {
      this.getImg()
    }
  },
  beforeRouteEnter(to, from, next) {
    if (Utils.getLocalStorage('userInfo')) {
      next()
    } else {
      next('/login')
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // if(val == oldVal){
        //     this.$refs['mainIframe'].contentWindow.location.reload()
        // } else{
        let Url
        if (val.fullPath.split('?link=').length > 0) {
          Url = val.fullPath.split('?link=')[1]
          Url = Url.split('&hash=')[0]
          if (Url.split('?').length > 1 || Url.split('%3F').length > 1) {
            Url = Url + '&hash=' + new Date().getTime()
          } else {
            Url = Url + '?hash=' + new Date().getTime()
          }
        }
        // this.$refs.mainIframe.contentWindow.location.replace(Url.replace(/%2F/g,"/").replace(/%23/g,"#").replace(/%3D/g,"=").replace(/%26/g,"&").replace(/%3F/g,"?"))

        this.iframeUrl = Url.replace(/%2F/g, '/').replace(/%23/g, '#').replace(/%3D/g, '=').replace(/%26/g, '&').replace(/%3F/g, '?')
        this.loadingFlagHome = true
        setTimeout(() => {
          this.loadingFlagHome = false
        }, 1000)
        //}
      },
      // 深度观察监听
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
@import './style/index.less';

.el-dropdown-menu {
  display: flex;
  li {
    div {
      font-size: 12px;
      line-height: 22px;
    }
  }
}
.color {
  color: rgb(22, 93, 255) !important;
}
.rightColor {
  color: red !important;
}

.el-dropdown-menu__item {
  border-radius: 3px;
  margin-right: 4px;
  padding: 0px 8px;
  color: rgb(78, 89, 105);
  background-color: rgb(242, 243, 245);
  position: relative;
  .menu {
    position: absolute;
    top: 26px;
    left: -15px;
    background-color: #ffffff;
    padding: 4px 0;
    border-radius: 4px;
    border: 1px solid rgb(229, 230, 235);
    box-shadow: 0 4px 10px;
    width: 146px;
    z-index: 99999;
    ul {
      li {
        padding: 0px 12px;
        display: flex;
        font-size: 14px;
        line-height: 36px;
        border-bottom: 1px solid rgb(229, 230, 235);
      }
    }
  }
}

.one {
  color: black !important;
}
.font_span {
  color: rgb(78, 89, 105) !important;
}
.icon_password {
  color: rgb(78, 89, 105) !important;
}
.main_header {
  border-bottom: 1px solid rgb(229, 230, 235);
}
.menuShouqi {
  cursor: pointer;
  margin-right: 4px;
  opacity: 0.9;
}
.home-main {
  /deep/.rs-tabs__header {
    display: none;
  }
}
.content {
  padding: 0px 5px;
}

.tab-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 12px !important;
  .right {
    flex: 1;
  }
  /deep/.rs-tabs__header {
    border-bottom-width: 0;
    margin: 0;
  }
  /deep/.rs-tabs__item {
    color: rgb(78, 89, 105) !important;
    background: #f2f3f5;
    padding: 0px 10px !important;
    border-radius: 4px;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    margin-left: 12px;
    &:hover {
      background: rgb(242, 243, 245);
    }
  }
  /deep/.rs-tabs__item.is-active {
    color: rgb(22, 93, 255) !important;
    background: rgb(242, 243, 245);
    border-width: 0;
    height: 32px;
    line-height: 32px;
  }
  .custom-contextmenu {
    transform-origin: center top;
    z-index: 2190;
    position: fixed;
    .el-dropdown-menu__item {
      font-size: 12px !important;
      white-space: nowrap;
      i {
        font-size: 12px !important;
      }
    }
  }
  /deep/.rs-tabs__nav-next,
  /deep/.rs-tabs__nav-prev {
    line-height: 32px;
    color: #c1c6c8;
    font-size: 14px;
    width: 32px;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    &:hover {
      background: rgba(0, 110, 255, 0.2);
    }
  }
  .close-all-button {
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
    padding: 0px 8px !important;
    border-width: 0;
    border-radius: 0 !important;
    margin-left: 10px;
    &:hover {
      background: rgba(0, 110, 255, 0.2);
    }
  }
}
.booth-box {
  min-height: 368px;
  max-height: 560px;
  overflow-y: scroll;
}
.orgrole-wrap {
  display: flex;
  flex-wrap: wrap;
}
.orgrole-item {
  padding: 0px 12px;
  display: block;
  margin-right: 16px;
  margin-bottom: 16px;
  display: flex;
  font-size: 14px;
  align-items: center;
  width: 160px;
  height: 32px;
  color: #c1c6c8;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  justify-content: space-between;
  &:hover {
    background: rgba(0, 110, 255, 0.2);
    color: #ffffff;
  }
  &:active,
  &.orgrole-item-active {
    background: #006eff;
    color: #ffffff;
  }
  span {
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  i {
    font-size: 14px;
    margin-left: 9px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
.all-tabs-popover {
  background: #21335c !important;
  padding: 20px !important;
  .popper__arrow::after {
    border-bottom-color: #21335c !important;
  }
}
.rs-container.main-container .rs-main {
  margin: 0px !important;
}
.ll {
  box-sizing: border-box;
  padding: 4px 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 10px #0000001a;
  color: rgb(29, 33, 41);
  padding: 0 12px;
  line-height: 36px;
  li {
    border-bottom: 1px solid rgb(229, 230, 235) !important;

    i {
      margin-left: 10px;
    }
    span {
      margin-left: 10px;
    }
  }
}
</style>
