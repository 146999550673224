var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.isCollapse ? "main_menu_thin" : "main_menu" },
    [
      _c(
        "rs-menu",
        {
          class: [{ thin: _vm.isCollapse }, "menu rs-menu-vertical-demo"],
          attrs: {
            collapse: _vm.isCollapse,
            "background-color": "#ffffff",
            "text-color": "#FFFFFF",
            "active-text-color": "#3370FF",
            "unique-opened": true,
            defaultActive: _vm.defaultActive,
          },
          on: {
            select: function ($event) {
              return _vm.selectItems($event)
            },
          },
        },
        _vm._l(_vm.menu.childResourceVoList, function (item, index) {
          return _c(
            "div",
            { key: index, staticStyle: { "margin-top": "4px" } },
            [
              item.childResourceVoList !== null
                ? _c(
                    "rs-submenu",
                    {
                      attrs: {
                        index: item.resourceName + "&" + item.resourceUrl,
                      },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#/?link=" + item.resourceUrl },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.prevent.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "font_family menuIcon",
                              domProps: { innerHTML: _vm._s(item.iconCode) },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isCollapse,
                                    expression: "!isCollapse",
                                  },
                                ],
                                staticClass: "title_span",
                                class: { paddingRight: !_vm.isCollapse },
                                attrs: { slot: "title" },
                                slot: "title",
                              },
                              [_vm._v(_vm._s(item.resourceName))]
                            ),
                          ]
                        ),
                      ]),
                      _vm._l(item.childResourceVoList, function (value, num) {
                        return _c(
                          "div",
                          { key: num, staticStyle: { "margin-top": "4px" } },
                          [
                            value.childResourceVoList == null
                              ? _c(
                                  "rs-menu-item",
                                  {
                                    key: num,
                                    staticClass: "menuitem-sub",
                                    attrs: {
                                      index:
                                        value.resourceName +
                                        "&" +
                                        value.resourceUrl,
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "two",
                                        attrs: {
                                          href: "#/?link=" + value.resourceUrl,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.prevent.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(value.resourceName))]
                                    ),
                                  ]
                                )
                              : _c(
                                  "rs-submenu",
                                  {
                                    attrs: {
                                      index:
                                        value.resourceName +
                                        "&" +
                                        value.resourceUrl,
                                      "popper-append-to-body": true,
                                    },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menuitem-sub",
                                          attrs: {
                                            href:
                                              "#/?link=" + value.resourceUrl,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.prevent.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "title_span",
                                              class: {
                                                paddingRight: !_vm.isCollapse,
                                              },
                                              attrs: { slot: "title" },
                                              slot: "title",
                                            },
                                            [_vm._v(_vm._s(value.resourceName))]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._l(
                                      value.childResourceVoList,
                                      function (val, idx) {
                                        return _c(
                                          "rs-menu-item",
                                          {
                                            key: idx,
                                            staticStyle: {
                                              "margin-top": "4px",
                                            },
                                            attrs: {
                                              index:
                                                val.resourceName +
                                                "&" +
                                                val.resourceUrl,
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "menuitem-thr",
                                                attrs: {
                                                  href:
                                                    "#/?link=" +
                                                    val.resourceUrl,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.prevent.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(val.resourceName))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _c(
                    "rs-menu-item",
                    {
                      class: { paddingRight: !_vm.isCollapse },
                      attrs: {
                        index: item.resourceName + "&" + item.resourceUrl,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#/?link=" + item.resourceUrl },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.prevent.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "font_family menuIcon",
                            domProps: { innerHTML: _vm._s(item.iconCode) },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isCollapse,
                                  expression: "!isCollapse",
                                },
                              ],
                              staticClass: "title_span",
                              class: { paddingRight: !_vm.isCollapse },
                            },
                            [_vm._v(_vm._s(item.resourceName))]
                          ),
                        ]
                      ),
                    ]
                  ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }