var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "rs-container",
    { staticStyle: { height: "100%", "min-width": "1080px" } },
    [
      _vm.maskFlag ? _c("div", { staticClass: "msgDivImg" }) : _vm._e(),
      _c(
        "rs-header",
        {
          staticClass: "main_header",
          staticStyle: { height: "52px", background: "#ffffff" },
        },
        [
          _c("p", { staticClass: "header_title" }, [
            _c("span", { staticClass: "logo" }, [
              _c("img", {
                attrs: {
                  src:
                    _vm.imgForm.logoImgType1 == "1"
                      ? _vm.imgForm.customIndexLogo
                      : _vm.imgForm.customIndexDefaultLogo,
                },
              }),
              _c("p", { staticClass: "one" }, [
                _vm._v(_vm._s(_vm.imgForm.customIndexTitle)),
              ]),
            ]),
            _c("span", {
              staticClass: "font_family menuShouqi",
              domProps: {
                innerHTML: _vm._s(!_vm.isCollapse ? "&#xe77b;" : "&#xe77a;"),
              },
              on: {
                click: function ($event) {
                  _vm.isCollapse = !_vm.isCollapse
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "button_block" },
            [
              _c(
                "rs-dropdown",
                {
                  staticClass: "user_info_block",
                  attrs: { trigger: "click" },
                  on: {
                    command: _vm.handleCommand,
                    "visible-change": _vm.changeClass,
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "user_info", class: { cur: _vm.show } },
                    [
                      _c("span", { staticClass: "font_span" }, [
                        _vm._v(_vm._s(_vm.userName)),
                      ]),
                    ]
                  ),
                  _c(
                    "rs-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("rs-dropdown-item", { attrs: { command: "change" } }, [
                        _c("i", { staticClass: "icon_password" }),
                        _vm._v("\n            修改密码\n          "),
                      ]),
                      _c("rs-dropdown-item", { attrs: { command: "logout" } }, [
                        _c("i", { staticClass: "icon_logout" }),
                        _vm._v("\n            退出登录\n          "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "rs-container",
        {
          staticClass: "main-container",
          staticStyle: { height: "calc(100% - 52px)" },
        },
        [
          _c("menu-div", {
            ref: "menu",
            attrs: { isCollapse: _vm.isCollapse },
            on: {
              jumpUrl: _vm.jumpUrl,
              hasSy: _vm.hasSy,
              getMenuResource: _vm.getMenuResource,
            },
          }),
          _c(
            "rs-main",
            {
              staticStyle: {
                "box-shadow": "0px 1px 4px 0px rgba(0, 0, 0, 0.2)",
              },
            },
            [
              _c("div", { staticClass: "main rs-container tab-top" }, [
                _c("div", { staticClass: "left" }, [
                  _c(
                    "ul",
                    { staticClass: "el-dropdown-menu" },
                    _vm._l(_vm.editableTabs, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "el-dropdown-menu__item",
                          attrs: { "aria-disabled": "false", tabindex: "-1" },
                          on: {
                            click: function ($event) {
                              return _vm.tabClick(item, index, 1)
                            },
                            contextmenu: function ($event) {
                              $event.preventDefault()
                              return _vm.onRightClick(item, index)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: {
                                content: _vm.content,
                                color: _vm.activeIndex == index,
                                rightColor: _vm.isShowIndex == index,
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.title))]),
                              index !== 0
                                ? _c("i", {
                                    staticClass: "rs-icon-close",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.removeTab(item.name)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm.isShowIndex == index
                            ? _c("div", [
                                _vm.meunVisiable
                                  ? _c(
                                      "ul",
                                      { staticClass: "menu ll" },
                                      _vm._l(_vm.meunList, function (v, k) {
                                        return _c(
                                          "li",
                                          {
                                            key: k,
                                            on: {
                                              click: function ($event) {
                                                return _vm.jumpPage(v, k, index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              class: "iconfont " + v.icon,
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(v.title)),
                                            ]),
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c(
                "rs-tabs",
                {
                  staticClass: "main rs-container is-vertical home-main",
                  staticStyle: { height: "100%" },
                  attrs: { type: "card" },
                  on: { "tab-remove": _vm.removeTab },
                  model: {
                    value: _vm.editableTabsValue,
                    callback: function ($$v) {
                      _vm.editableTabsValue = $$v
                    },
                    expression: "editableTabsValue",
                  },
                },
                _vm._l(_vm.editableTabs, function (item) {
                  return _c(
                    "rs-tab-pane",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: item.loading,
                          expression: "item.loading",
                        },
                      ],
                      key: item.name + (item.randomKey || ""),
                      ref: "frameTab",
                      refInFor: true,
                      staticClass: "rs-main",
                      attrs: {
                        "element-loading-text": "拼命加载中",
                        "element-loading-spinner": "rs-icon-loading",
                        "element-loading-background":
                          "rgba(249, 249, 249, 0.8)",
                        label: item.title,
                        name: item.name,
                        closable: item.closeable,
                      },
                    },
                    [
                      _c("iframe", {
                        ref: "mainIframe",
                        refInFor: true,
                        attrs: { id: "main_iframe", src: item.url },
                      }),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("change-password", {
        ref: "ChangePassword",
        attrs: { showPassword: _vm.showPassword },
        on: { hidePassword: _vm.hidePassword },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }