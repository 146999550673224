var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "rs-dialog",
    {
      staticClass: "newProduct_block form-dialog",
      attrs: {
        title: "修改密码",
        visible: _vm.showPassword,
        "close-on-click-modal": false,
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showPassword = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        {},
        [
          _c(
            "rs-form",
            {
              ref: "ruleForm2",
              staticClass: "demo-ruleForm commom-form",
              attrs: {
                model: _vm.ruleForm2,
                "status-icon": "",
                rules: _vm.rules2,
                size: "small",
              },
            },
            [
              _c(
                "rs-form-item",
                { staticClass: "mg-b-10", attrs: { prop: "originalPassword" } },
                [
                  _c("rs-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "new-password",
                      placeholder: "旧密码",
                    },
                    model: {
                      value: _vm.ruleForm2.originalPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm2, "originalPassword", $$v)
                      },
                      expression: "ruleForm2.originalPassword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "rs-form-item",
                { staticClass: "mg-b-10", attrs: { prop: "newpass" } },
                [
                  _c("rs-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "new-password",
                      placeholder: "新登录密码",
                    },
                    model: {
                      value: _vm.ruleForm2.newpass,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm2, "newpass", $$v)
                      },
                      expression: "ruleForm2.newpass",
                    },
                  }),
                ],
                1
              ),
              _c(
                "rs-form-item",
                { staticClass: "mg-b-0", attrs: { prop: "checkPass" } },
                [
                  _c("rs-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "new-password",
                      placeholder: "确认新密码",
                    },
                    model: {
                      value: _vm.ruleForm2.checkPass,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm2, "checkPass", $$v)
                      },
                      expression: "ruleForm2.checkPass",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "rs-button",
        {
          staticClass: "submit_btn",
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.submitForm("ruleForm2")
            },
          },
        },
        [_vm._v("确定")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }